import {useMachine} from '@xstate/react';
import {useSubscription} from 'observable-hooks';
import {
  ContentModule,
  ModuleProperties,
  useShowId,
  useShowInstructions,
} from '@backstage-components/base';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  Text,
} from '@chakra-ui/react';
import {cx, css} from '@emotion/css';
import {useCallback, useEffect, VFC} from 'react';
import {FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import {
  SchemaType,
  PublicAccessCodeInstructionSchema,
} from './PublicAccessCodeDefinition';

import {
  PublicAccessCodeMachine,
  type PublicAccessCodeSuccessEvent,
} from './PublicAccessCodeMachine';

import {PublicAccessCodeViewMachine} from './PublicAccessCodeViewMachine';

export type PublicAccessCodeProps = ModuleProperties & SchemaType;

export type PublicAccessCodeComponentDefinition = ContentModule<
  'PublicAccessCode',
  PublicAccessCodeProps
>;

export const PublicAccessCodeComponent: VFC<
  PublicAccessCodeComponentDefinition
> = (definition) => {
  // Include at least `definition.style` as part of the outer-most component
  // returned. `definition.style` is how layouts pass size information.
  const styleClassName = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;

  const {
    title,
    subtitle,
    buttonLabel,
    codeInputValidation,
    nameInputValidation,
    codeInputPlaceholder,
    nameInputPlaceholder,
    codeErrorMessage,
  } = definition.props;

  const showId = useShowId();
  const {observable, broadcast} = useShowInstructions(
    PublicAccessCodeInstructionSchema,
    definition
  );

  const [state, dispatch] = useMachine(PublicAccessCodeMachine, {
    context: {broadcast, showId},
  });
  const [, viewDispatch] = useMachine(PublicAccessCodeViewMachine, {
    context: {broadcast},
  });

  const form = useForm({
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    setError,
    clearErrors,
    register,
    formState: {errors},
  } = form;

  useEffect(() => {
    if (state.matches('failure')) {
      setError('passCode', {
        message: state.context.reason?.includes('InvalidPublicPasscode')
          ? codeErrorMessage
          : state.context.reason,
      });
    } else {
      clearErrors('passCode');
    }
  }, [clearErrors, codeErrorMessage, setError, state]);

  useSubscription(observable, {
    next: (instruction) => dispatch(instruction),
  });
  useSubscription(observable, {
    next: (instruction) => viewDispatch(instruction),
  });

  useEffect(() => {
    const onPublicAccessCodeSuccess = (
      e: PublicAccessCodeSuccessEvent
    ): void => {
      const {detail} = e;
      broadcast({
        type: 'PublicAccessCode:on-success',
        meta: {
          showId: detail.showId,
          attendeeId: detail.attendee.id,
          attendeeEmail: detail.attendee.email,
          attendeeName: detail.attendee.name,
          attendeeTags: detail.attendee.tags.join(','),
        },
      });
    };
    document.body.addEventListener(
      'PublicAccessCode:success',
      onPublicAccessCodeSuccess
    );
    return () => {
      document.body.removeEventListener(
        'PublicAccessCode:success',
        onPublicAccessCodeSuccess
      );
    };
  }, [broadcast]);

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      // Send the instruction to the state machine, which will trigger the
      // broadcast
      if (typeof data.passCode !== 'string' || typeof data.name !== 'string') {
        return;
      }

      broadcast({
        type: 'PublicAccessCode:verify',
        meta: {
          passCode: data.passCode.toUpperCase(),
          showId,
          name: data.name,
          moduleId: '',
        },
      });
    },
    [broadcast, showId]
  );

  return (
    <Flex
      direction="column"
      className={cx(styleClassName, definition.mid)}
      gap={6}
      alignItems="center"
      id={definition.id}
    >
      <Flex gap={2} direction="column" alignItems="center" textAlign="center">
        <Heading className="public-ac-title" fontSize="3rem">
          {title}
        </Heading>
        <Text className="public-ac-subtitle" fontSize="0.875rem">
          {subtitle}
        </Text>
      </Flex>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          className="public-ac-form-container"
          w="21.875rem"
          gap={6}
          direction="column"
        >
          <FormControl isInvalid={errors.name}>
            <InputGroup flexDirection="column" alignItems="center">
              <Input
                aria-label="full name"
                className="name"
                h="3.125rem"
                fontSize="0.875rem"
                isInvalid={errors.name}
                borderRadius="0.625rem"
                placeholder={nameInputPlaceholder}
                {...register('name', {
                  required: nameInputValidation?.required,
                })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </InputGroup>
          </FormControl>
          <FormControl isInvalid={errors.passCode}>
            <InputGroup flexDirection="column" alignItems="center">
              <Input
                aria-label="password"
                className="passCode"
                h="3.125rem"
                fontSize="0.875rem"
                isInvalid={errors.passCode}
                borderRadius="0.625rem"
                {...register('passCode', {
                  required: codeInputValidation?.required,
                })}
                textTransform="uppercase"
                placeholder={codeInputPlaceholder}
                _placeholder={{
                  textTransform: 'none',
                }}
              />
              <FormErrorMessage>
                {errors.passCode && errors.passCode.message}
              </FormErrorMessage>
            </InputGroup>
          </FormControl>
          <Button
            className="submit-button"
            type="submit"
            bg="black"
            color="white"
            fontSize="1rem"
            borderRadius="0.625rem"
            h="3.125rem"
            _hover={{
              bg: '#484848',
            }}
            _active={{
              bg: '#484848',
            }}
            isLoading={state.matches('pending')}
          >
            {buttonLabel}
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};
